.formContainer{
    text-align: center;
    max-width: 720px !important;
    .inputLabel{
        font-weight: bold;
    }
    .formCol{
        padding: 0px 70px 70px 70px;
    }
    .buttonCol{
        text-align: left;
        padding: 30px;
        .backButton{
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }

    .backLink{
        border: 0px !important;
    }
    .react-datetime-picker__wrapper{
        border-radius: 5px;
        border: 1px solid #ced4da;
        padding: 5px;
    }
    .dropzone{
        height: 60px;
        margin-bottom: 30px;
        margin-top: 30px;
        padding: 10px;
        border: 1px dashed #ced4da;
        //font-weight: bold;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    .active{
        border: 1px solid black;
    }
}