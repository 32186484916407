
    .imgBig{
        
        width: 500px;
    }
    .imgSmall{
        
        width: 350px;
    }
    h4{
        padding: 10px;
        
    }
    p{
        margin-top: 1rem !important;
    }
    .update{
        padding: 8px !important;
        margin: 10px;
    }
    .delete{
        margin: 10px;
    }
