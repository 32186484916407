
.welcomeDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  h1{
    padding: 25px;
    font-size: 30px;
  }
}