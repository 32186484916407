
.headerContainer{
    .colWrapper{
        margin-top: 35px;
        .textCol{
            font-size: 30px;
        }
        .loginButton{
            background-color: #0038a8;
            border-color: #0038a8;
        }
    }   

}
